import React from 'react';

/**
 * Site footer component
 */
const Footer = () => (
  <footer>
   

    <div className="copyright" id="copyright">
                  &copy;
                  2019, Crafted lovingly in London by
          <a href="" target="_blank"></a> Sridhar Sethuraman
          <a href="" target="_blank"></a>.
        </div>
  </footer>
);

export default Footer;
